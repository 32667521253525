import React, {useEffect, useState} from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { getValue } from '../Storage';
import Constants from 'expo-constants';
import config from '../config.json';
const { WEBSITE_URL, LOGO_URL } = config;

export default function MembershipCard() {
    const [member, setMember] = useState({
        name: '',
        number: '',
    });

    useEffect(() => {
        const loadMemberDetails = async () => {
            const member = await getValue('member');
            setMember({
                name: member?.name || '',
                number: member?.number || ''
            });
        };
        loadMemberDetails();
    }, []);

    return (
        <View style={styles.card}>
            <Image source={{uri: WEBSITE_URL + LOGO_URL}} style={{width: 200, height: 45, marginBottom: 48}} />
            <Text style={styles.title}>Member</Text>
            <Text style={styles.name}>{member.name} {member.number}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: '#FFFFFF',
        backgroundImage: 'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="382" height="200" fill="%23003366"><path d="M 382 98V0H0V181.7c37.1 0.4 75.7-0.4 112.45-5.9c18.1-2.7 36.15-5.9 54.05-9.6c27.9-5.75 55.2-13.8 82.05-23.35c27.9-9.95 55.6-20.5 83.6-30.2c13.95-4.8 28.2-8.6 49.85-14.65"/></svg>\')',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        borderRadius: 10,
        display: 'flex',
        maxWidth: 300,
        padding: 30,
        width: '100%',
    },
    title: {
        fontSize: 30,
        textAlign: 'right',
    },
    name: {
        fontSize: 20,
        textAlign: 'right',
        textTransform: 'uppercase',
    }
});
