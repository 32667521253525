
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import MembershipCard from '../components/MembershipCard';
import config from '../config.json';

export default function CardScreen({ navigation }) {
    return (
        <View style={styles.container}>
            <Text style={styles.heading}>
                { config.content.page.card.title }
            </Text>
            <Text style={styles.text}>
                { config.content.page.card.description }
            </Text>
            <MembershipCard />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        backgroundImage: 'linear-gradient(0deg, #002649 -20%, #08142B 52%)',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        fontFamily: '"Barlow", Helvetica, Arial, sans-serif',
        color: '#FFFFFF',
        lineHeight: '1.6',
        width: '100%',
    },
    heading: {
        color: "#FFFFFF",
        display: 'flex',
        fontSize: 30,
        fontWeight: 900,
        marginBottom: 8,
        maxWidth: 300,
        textTransform: 'uppercase',
        width: '100%',
    },
    text: {
        color: "#FFFFFF",
        display: 'flex',
        fontSize: 16,
        marginBottom: 24,
        maxWidth: 300,
        width: '100%',
    },
    hidden: {
        display: 'none'
    }
});
