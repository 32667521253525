import React, { useState } from 'react';
import { Platform, Pressable, StyleSheet, Image } from 'react-native';

function NotificationButtonButton() {
    let [showNotificationButton, setShowNotificationButton] = useState(true);

    if (showNotificationButton && Platform.OS === 'web') {
        if (Notification.permission === 'granted') {
            setShowNotificationButton(false);
        }
    }

    function toggleNotifications() {
        if (Platform.OS === 'web') {
            if (Notification.permission === 'granted') {
                setShowNotificationButton(false);
            }
            else {
                Notification.requestPermission().then(function (permission) {
                    if (permission === 'granted') {
                        setShowNotificationButton(false);
                    }
                });
            }
        }
    }

    function notificationClick(event) {
        event.preventDefault();
        window.open(event.target.data.url, '_blank');
    }

    return showNotificationButton ? (
        <Pressable onPress={toggleNotifications} style={styles.button}>
            <Image source={require('../media/icon-notification.svg')} style={styles.icon} />
        </Pressable>
    ) : null;
};

export default NotificationButtonButton;

const styles = StyleSheet.create({
    button: {
        marginHorizontal: '0.5rem',
        paddingBlock: '0.75rem',
        paddingInline: '0.75rem',
    },
    icon: {
        width: '2rem',
        height: '2rem',
    },
});
