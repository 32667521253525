import Constants from 'expo-constants';
import * as Device from 'expo-device';
import config from './config.json';
const { WEBSITE_URL, API_ENDPOINT } = config;
const API_BASE_URL = WEBSITE_URL + API_ENDPOINT;

/**
 * Create a new application password given the supplied username, password and device name.
 */
const login = async (values) => { // {username: client.username, password: client.password}
    try {
        // Passwords need to be unique per device, so we'll use the device and/or model name to generate a unique name to go with it.
        values.deviceName = values.deviceName || Constants.deviceName;
        values.modelName  = values.modelName  || Device.modelName;
        const response = await fetch(`${API_BASE_URL}/authorization/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        });

        if (!response.ok) {
            switch (response.status) {
                case 403:
                    console.log('Login failed. Please check your username and password and try again.');
                    throw new Error('Login Failed');
                default:
                    console.log('Login unsuccessful. Please try again later.');
                    throw new Error('Login Unsuccessful');
            }
        }
        
        // Returns a string with the password.
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
    return '';
};

/**
 * Verify that the supplied username and application password are still valid.
 */
const validateLogin = async (values) => { // {username: app.username, password: app.password}
    const output = {
        status: 0,
        message: "",
        data: {},
    };
    try {
        const response = await fetch(`${API_BASE_URL}/authorization/validate/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        });
        output.status = response.ok ? 1 : 0;
        output.data = await response.json();
    } catch (error) {
        console.error('Error during login validation:', error);
        output.status = -1;
        output.message = error;
    }
    return output;
};

/**
 * Log the user out of one or more connections.
 */
const logout = async (values) => { // {username: app.username, password: app.password, current: 1, others: 0}
    try {
        const response = await fetch(`${API_BASE_URL}/authorization/logout/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: values.username,
                password: values.password,
                current:  values.current,
                others:   values.others
            })
        });

        if (!response.ok) {
            console.log('Logout Failed.');
            throw new Error('Logout Failed');
        }

        const data = await response.json();
        // Returns a number with the number of application passwords that were deleted.
        console.log(data);
        return data;
    } catch (error) {
        console.log(error);
    }
    return false;
};

export { login, validateLogin, logout };
