import React from 'react';
import { Image, Pressable, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { deleteValue, getValue } from '../Storage';
import * as API from '../Api';

const LogoutButton = () => {
    const navigation = useNavigation();

    const handleLogout = () => {
        getValue('username').then(username => {
            getValue('password').then(password => {
                const values = {
                    username: username,
                    password: password,
                    current: 1
                };
                API.logout(values);
                deleteValue('password');
                deleteValue('member');
            });
        });
        navigation.replace('Login');
    };

    return (
        <Pressable onPress={handleLogout} style={styles.button}>
            <Image source={require('../media/icon-logout.svg')} style={styles.icon} />
        </Pressable>
    );
};

export default LogoutButton;

const styles = StyleSheet.create({
    button: {
        border: 0,
        borderRadius: '3px',
        fontWeight: '500',
        marginHorizontal: '0.5rem',
        paddingBlock: '0.75rem',
        paddingInline: '0.75rem',
    },
    icon: {
        width: '1.5rem',
        height: '1.5rem',
    },
});