import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet } from 'react-native';
import SplashScreen from './screens/SplashScreen';
import LoginScreen from './screens/LoginScreen';
import CardScreen from './screens/CardScreen';
import ConfigScreen from './screens/ConfigScreen';
import HomeScreen from './screens/HomeScreen';
import NotificationButton from './components/NotificationButton';
import SettingsButton from './components/SettingsButton';
import BackButton from './components/BackButton';
import LogoutButton from './components/LogoutButton';

const Stack = createStackNavigator();

function App() {
    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName="Load">
                <Stack.Group
                    screenOptions={{
                        headerTintColor: '#fff',
                        headerStyle: styles.header,
                    }}
                >
                    <Stack.Screen name="Load" component={SplashScreen} options={{
                        headerShown: false,
                    }} />

                    <Stack.Screen name="Login" component={LoginScreen} options={{
                        headerShown: false,
                    }} />

                    <Stack.Screen name="Card" component={CardScreen} options={{
                        title: 'Card',
                        headerLeft: () => <BackButton />,
                    }} />

                    <Stack.Screen name="Settings" component={ConfigScreen} options={{
                        title: 'Settings',
                        headerLeft: () => <BackButton />,
                        headerRight: () => <NotificationButton />,
                    }} />

                    <Stack.Screen name="Home" component={HomeScreen} options={{
                        headerLeft: () => <LogoutButton />,
                        headerRight: () => <SettingsButton />,
                    }} />
                </Stack.Group>
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default App;

const styles = StyleSheet.create({
    header: {
        backgroundColor: '#000D23',
        borderWidth: 0,
    }
});
