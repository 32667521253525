import { Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';

const storeValue = async (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        // LocalStorage needs to be used when testing on web.
        if (Platform.OS === 'web') {
            localStorage.setItem(key, serializedValue);
            console.log('Value ' + key + ' saved to localStorage.');
            return;
        }
        await SecureStore.setItemAsync(key, serializedValue);
        console.log('Value ' + key + ' stored securely.');
    } catch (error) {
        console.log('Error saving ' + key, error);
    }
};

const getValue = async (key) => {
    try {
        // LocalStorage needs to be used when testing on web.
        if (Platform.OS === 'web') {
            console.log('Attempting to get ' + key + ' from localStorage.');
            return JSON.parse(localStorage.getItem(key));
        }
        const value = await SecureStore.getItemAsync(key);
        if (!value) {
            console.log('No ' + key + ' saved');
            return null;
        }
        return JSON.parse(value);
    }
    catch (error) {
        console.log('Error obtaining ' + key, error);
    }
};

const deleteValue = async (key) => {
    try {
        if (!key && Platform.OS === 'web') {
            localStorage.clear();
            console.log('All localStorage data cleared.');
            return;
        }
        // Use localStorage for web platform
        if (Platform.OS === 'web') {
            localStorage.removeItem(key);
            console.log('Value ' + key + ' removed from localStorage.');
            return;
        }
        // Use SecureStore for other platforms
        if (key) {
            await SecureStore.deleteItemAsync(key);
            console.log('Value ' + key + ' removed from SecureStore.');
        }
    } catch (error) {
        console.log('Error deleting ' + key, error);
    }
};

export { storeValue, getValue, deleteValue };
