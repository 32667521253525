import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useState} from 'react';
import { Formik } from 'formik';
import { StyleSheet, Text, Image, Pressable, Linking, View } from 'react-native';
import { storeValue, getValue } from '../Storage';
import * as API from '../Api';
import config from '../config.json';
const { WEBSITE_URL, LOGO_URL } = config;

export default function LoginScreen({ navigation }) {
    const [userCredentials, setUserCredentials] = useState({
        username: '',
        password: '',
    });

    useEffect(() => {
        const loadStoredCredentials = async () => {
            const username = await getValue('username');
            setUserCredentials({ username: username || '', password: '' });
        };
        loadStoredCredentials();
    }, []);

    function visitForgotPasswordPage() {
        Linking.openURL(config.WEBSITE_URL + config.links.forgot);
    }

    const handleLogin = async (values, actions) => {
        const password = await API.login(values);
        // Always update username and password, even if it is false.
        storeValue('username', values.username);
        storeValue('password', password);
        if (password) {
            // Redirect back to the splash screen to obtain and store member details.
            navigation.replace('Load');
        }
        else {
            actions.setFieldError('password', config.content.login.error.password);
        }
    };

    return (
        <View style={styles.container}>
            <Image source={{uri: WEBSITE_URL + LOGO_URL}} style={{width: 200, height: 45, marginBottom: '3rem'}}/>
            <Formik enableReinitialize initialValues={userCredentials} onSubmit={handleLogin}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        <label style={styles.label} >
                            { config.content.login.username }
                        </label>
                        <input type="text" name="username" onChange={handleChange} onBlur={handleBlur} value={values.username} style={styles.input} />
                        {errors.username && touched.username && errors.username}
                        <label style={styles.label} >
                            { config.content.login.password }
                        </label>
                        <input type="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} style={styles.input} />
                        {errors.password && touched.password && <p>{errors.password}</p>}
                        <button type="submit" disabled={isSubmitting} style={styles.button}>
                            { config.content.login.submit }
                        </button>
                        <Pressable onPress={visitForgotPasswordPage} style={styles.forgotButton}>
                            <Text style={styles.forgotButtonLabel}>
                                { config.content.login.forgot }
                            </Text>
                        </Pressable>
                    </form>
                )}
            </Formik>
            <StatusBar style="auto" />
        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundImage: 'linear-gradient(0deg, #002649 -20%, #08142B 52%)',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: '"Barlow", Helvetica, Arial, sans-serif',
    color: '#FFFFFF',
    paddingInline: '1rem',
    lineHeight: '1.6',
  },
  input: {
    color: '#F5F5F5',
    backgroundColor: 'transparent',
    border: '0',
    borderBottomWidth: '1px',
    borderBlockColor: '#6981B2',
    borderStyle: 'solid',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '0',
    paddingLeft: '0',
    fontSize: '16px',
    marginBottom: '1.5rem',
    display: 'block',
    width: '100%',
  },
  label: {
    display: 'block',
    fontWeight: '400',
  },
  button: {
    border: 0,
    borderRadius: '3px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: '500',
    backgroundColor: '#26CFFF',
    color: '#002059',
    paddingTop: '0.5rem',
    paddingLeft: '1.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '1.5rem',
  },
  forgotButton: {
    marginBlock: '0.75rem',
  },
  forgotButtonLabel: {
    color: '#a2a2a2'
  },
});
