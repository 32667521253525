import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Linking, View, Text, Pressable, StyleSheet } from 'react-native';
import config from '../config.json';

export default function SplashScreen({navigation}) {
    function visitMemberDashboardPage() {
        Linking.openURL(config.WEBSITE_URL + config.links.dashboard);
    }

    function viewCardPage() {
        navigation.replace('Card');
    }

    return (
        <View style={styles.container}>
            <StatusBar style="auto" />
            <span style={styles.spacer}></span>
            <Pressable onPress={visitMemberDashboardPage} style={styles.button}>
                <Text>{ config.content.button.dashboard }</Text>
            </Pressable>
            <span style={styles.spacer}></span>
            <Pressable onPress={viewCardPage} style={styles.button}>
                <Text>{ config.content.button.card }</Text>
            </Pressable>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundImage: 'linear-gradient(0deg, #002649 -20%, #08142B 52%)',
        alignItems: 'center', // Left <-> Right
        justifyContent: 'center', // Top <-> Bottom
        fontSize: '16px',
        fontFamily: '"Barlow", Helvetica, Arial, sans-serif',
        color: '#FFFFFF',
        lineHeight: '1.6',
    },
    bigButton: {
        backgroundColor: 'gold',
        display: 'block',
        height: 'auto',
        margin: '0.75rem',
        width: 'calc(50% - 1.5rem)',
    },
    buttons: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '1rem',
        paddingBlock: '2rem',
        width: 'calc(100% - 0rem)',
    },
    button: {
        backgroundColor: '#26cfff',
        borderRadius: 3,
        color: '#00163d',
        display: 'block',
        fontWeight: '500',
        marginBlock: '1rem',
        marginInline: '1rem',
        paddingBlock: '1rem',
        paddingInline: '2rem',
        textAlign: 'center',
        width: 'calc(100% - 2rem)',
    },
    spacer: {
        margin: 'auto',
    },
});
